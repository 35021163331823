import React, { useEffect, useState } from "react";
import { fetchQRCodeByUniqueCode } from "../api";
import getDeviceName from "./getDevice";

const QRRedirect =() => {
  const uniqueCode = window.location.pathname.split("/")[2];
  const [qrCodeData, setQrCodeData]=useState()
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();  
const deviceName =  getDeviceName();

useEffect(()=>{
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude)
      },
      (error) => {
        setLatitude('unavailable');
        setLongitude('unavailable');
      }
    )}else{
      setLatitude('unavailable')
      setLongitude('unavailable')
    }
  },[])
    
  useEffect(() => {
    if(latitude && longitude){
  const fetchData = async () => {
try {
  const qrCodeResponse = await fetchQRCodeByUniqueCode(
    uniqueCode,
    deviceName,
    latitude === 'unavailable'?undefined:latitude,
    longitude === 'unavailable'?undefined:longitude,
  );
  setQrCodeData(qrCodeResponse);
} catch (err) {
  console.error("Error fetching data:", err);
}
};

    fetchData(); 
}
  }, [deviceName, uniqueCode,longitude, latitude]); 


  const redirectUrl = () => {
    if (qrCodeData?.qrCodeData.emailAddress) {
      window.location.href = `mailto:${qrCodeData?.qrCodeData.emailAddress}`;
    } else if (qrCodeData?.qrCodeData.website) {
      window.location.href = `https://${qrCodeData?.qrCodeData.website}`;
    } else if (qrCodeData?.qrCodeData.address) {
      const searchQuery = encodeURIComponent(qrCodeData?.qrCodeData.address);
      window.location.href = `https://www.google.com/search?q=${searchQuery}`;
    } else if (qrCodeData?.qrCodeData.mobileNumber) {
      window.location.href = `tel:${qrCodeData?.qrCodeData.mobileNumber}`;
    }
  };
  

  return <>{redirectUrl()}</>;
};

export default QRRedirect;
