export default function getLocation(callback) {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const url = `https://nominatim.openstreetmap.org/reverse.php?lat=${latitude}&lon=${longitude}&format=jsonv2`;
 
          fetch(url)
            .then((response) => response.json())
            .then((locationData) => {
              resolve({ latitude, longitude}); // Successfully fetched location data
              callback({ latitude, longitude})
            })
            .catch((error) => {
              console.error("Error fetching location data:", error);
              callback('error')
              resolve(null); // Resolve as null on fetch error
            });
        },
        (error) => {
          // Handling error based on error code
          if (error.code === 1) {
            console.error("Error getting position: User denied Geolocation");
            callback("Error getting position: User denied Geolocation")
            resolve(null)
          } else {
            resolve(null)
            callback('error')
            console.error("Error getting position:", error);
          }
          resolve(null); // Resolve as null if user denies or another error occurs
        }
      );
    } else {
      callback('error')
      console.error(new Error("Geolocation not supported"));
      resolve(null); // Resolve as null if geolocation is not supported
    }
  });
}
