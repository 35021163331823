import React, { useState } from 'react';
import QRCodeForm from './QRCodeForm';
import QRComponent from './QRComponent';
import VQRComponent from './VQRComponent';
import './QRCodePopup.css';

const QRCodePopup = ({ onClose, updateQRCodes }) => {
  const [showPopup, setShowPopup] = useState(true);
const[qrCode, setQrCode]=useState();
const [activeTab, setActiveTab] = useState(0);


  const closeModal = () => {
    setShowPopup(false);
    onClose(); 
    updateQRCodes();
  };


  const renderQRComponent = () => {
    switch (activeTab) {
      case 0:  
        return <QRComponent value={qrCode?.emailAddress} name={qrCode?.qrName} />;
      case 1: 
        return <VQRComponent value={qrCode} name={qrCode?.qrName} />;
      case 2:
        return <QRComponent value={qrCode?.website}  name={qrCode?.qrName}/>;
      case 3: 
        return <QRComponent value={qrCode?.address} name={qrCode?.qrName}/>;
      case 4:
        return <QRComponent value={qrCode?.mobileNumber} name={qrCode?.qrName}/>;
      default:
        return null;
    }
  }; 

  return (
    <div className={`popup-container ${showPopup ? 'show' : 'hide'}`}>
      <div className="popup-content">
        <span className="close" onClick={closeModal}>&times;</span>
        <div className="content-wrapper">
          <QRCodeForm setQrCode={setQrCode}setActiveTab={setActiveTab}
          />
          <div className="qr-code-display-area">
          {renderQRComponent()}
          </div>
{/* } */}
        </div>
      </div>
    </div> 
  );
};

export default QRCodePopup;
