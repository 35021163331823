import {React, useState, useEffect} from "react";
import { fetchVQRCodeByUniqueCode } from "../api";
import getDeviceName from "./getDevice";
import './VQRRedirect.css'
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";


const VQRRedirect = () => {

  const uniqueCode = window.location.pathname.split("/")[3];
  const [qrCodeData, setQrCodeData]=useState() 
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);  
  const deviceName =  getDeviceName();

  useEffect(()=>{
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude)
        },
        (error) => {
          setLatitude('unavailable');
          setLongitude('unavailable');
        }
      )}else{
        setLatitude('unavailable')
        setLongitude('unavailable')
      }
    },[])
      
    useEffect(() => {
      if(latitude && longitude){
    const fetchData = async () => {
  try {
    const qrCodeResponse = await fetchVQRCodeByUniqueCode(
      uniqueCode,
      deviceName,
      latitude === 'unavailable'?undefined:latitude,
      longitude === 'unavailable'?undefined:longitude,
    );
    setQrCodeData(qrCodeResponse);
  } catch (err) {
    console.error("Error fetching data:", err);
  }
  };
  
      fetchData(); 
  }
    }, [deviceName, uniqueCode,longitude, latitude]); 
  
      
  const handleDownloadVCard = () => {
    if (!qrCodeData || !qrCodeData.qrCodeData) return;

    const { fullName, jobTitle, emailAddress, mobileNumber, address, website } = qrCodeData.qrCodeData;

    // vCard content
    const vCardContent = `
BEGIN:VCARD
VERSION:3.0
FN:${fullName}
ORG:${jobTitle}
TEL;TYPE=WORK,VOICE:${mobileNumber}
EMAIL:${emailAddress}
ADR;TYPE=WORK:;;${address}
URL:${website}
END:VCARD
    `.trim();

    // Create a Blob from the vCard content
    const blob = new Blob([vCardContent], { type: 'text/vcard' });

    // Create a temporary link element to download the vCard
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fullName}.vcf`;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(link.href);
  };
  
  return <>
 <div className="contact-page">
      <div className="v-card">
        <h2 className="name">V card, {qrCodeData?.qrCodeData?.fullName}</h2>
        <p className="job">{qrCodeData?.qrCodeData?.jobTitle}</p>
        <div className="contact-info">
          <div className="info-item">
            <i className="icon email"><EmailIcon/></i>
            <span>{qrCodeData?.qrCodeData?.emailAddress}</span>
            <p className="info-label">Email</p>
          </div>
          <div className="info-item">
            <i className="icon phone"><PhoneIcon/></i>
            <span>{qrCodeData?.qrCodeData?.mobileNumber}</span>
            <p className="info-label">Phone</p>
          </div>
          <div className="info-item">
            <i className="icon location"><HomeIcon/></i>
            <span>{qrCodeData?.qrCodeData?.address}</span>
            <p className="info-label">Address</p>
          </div>
          <div className="info-item">
            <i className="icon link"><LinkIcon/></i>
            <span>{qrCodeData?.qrCodeData?.website}</span>
            <p className="info-label">Url</p>
          </div>
        </div>
        <button className="add-to-contacts" onClick={handleDownloadVCard}>+ Add to Contacts</button>
      </div>
    </div>
   </>;
};

export default VQRRedirect;
