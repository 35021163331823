//QRCodePopUpUpdate.js
import React, { useState } from 'react';
import QRCodeForm from './QRCodeForm'; 
import QRComponent from './QRComponent';
import VQRComponent from './VQRComponent';
import './QRCodePopup.css';

const QRCodePopupUpdate = ({ qrId, onClose, updateQRCodes, qrData, qrType }) => {
  const[qrCode, setQrCode]=useState();
  const [activeTab, setActiveTab] = useState(0); 
  const handleClose = () => {
    onClose();
    updateQRCodes()
  };

  const renderQRComponent = () => {
    switch (activeTab) {
      case 0: 
        return <QRComponent value={qrCode?.emailAddress} name={qrCode?.qrName} />;
      case 1:
        return <VQRComponent value={qrCode} name={qrCode?.qrName} />;
      case 2: 
        return <QRComponent value={qrCode?.website}  name={qrCode?.qrName}/>;
      case 3: 
        return <QRComponent value={qrCode?.address} name={qrCode?.qrName}/>;
      case 4: 
        return <QRComponent value={qrCode?.mobileNumber} name={qrCode?.qrName}/>;
      default:
        return null;
    }
  };


  return (
    <div className={`popup-container ${qrId ? 'show' : 'hide'}`}>
      <div className="popup-content">
        <span className="close" onClick={handleClose}>&times;</span>
        <div className="content-wrapper">
        <QRCodeForm qrId={qrId} onClose={handleClose} edit={true} qrData={qrData}  qrType={qrType} setQrCode={setQrCode}setActiveTab={setActiveTab}/>
        <div className="qr-code-display-area">
          {renderQRComponent()}
          </div> 
        </div>
      </div> 
    </div>
  ); 
};

export default QRCodePopupUpdate;