const token = localStorage.getItem("qrg:access-token");
const baseURL = process.env.REACT_APP_BASE_URL
const sisURL = process.env.REACT_APP_SIS_URL

export const generateQRCode = async (qrData) => {
  try {
    const response = await fetch(`${baseURL}/qrcode/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(qrData),
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData.qrCodeData; // Extract the QR code data from the response
    } else {
      throw new Error(responseData.message || "Failed to generate QR code");
    }
  } catch (error) {
    throw error;
  }
};
 
export const generateVQRCode = async (qrData) => {
  try {
    const response = await fetch(`${baseURL}/qrcode/generatev`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(qrData),
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData.qrCodeData; // Extract the QR code data from the response
    } else {
      throw new Error(responseData.message || "Failed to generate QR code");
    }
  } catch (error) {
    throw error;
  }
};

export const getQRCodes = async (userId) => {
  try {
    const response = await fetch(`${baseURL}/qrcode/get/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch QR codes");
    }
    const data = await response.json();
    return data.response || []; 
  } catch (error) {
    console.error(error);
    return []; 
  }
};

export const getAllQRCodes = async (token) => {
  try {
    const response = await fetch(`${baseURL}/qrcode/getAllQrs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch all QR codes");
    }
    const data = await response.json();
    return data.allQRCodes || []; 
  } catch (error) {
    console.error(error);
    return []; 
  }
};

export const getUserdata = async (token) => {
  try {
    const response = await fetch(`${sisURL}/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteQRCode = async (qrId, qrType) => {
  try {
    let response;
    if (qrType === "QRCode") {
      response = await fetch(`${baseURL}/qrcode/delete/${qrId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } else if (qrType === "VQRCode") {
      response = await fetch(`${baseURL}/qrcode/deletev/${qrId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }
    const data = await response.json();
    return data; 
  } catch (error) {
    throw error;
  }
};

export const updateQRCode = async (qrId, updatedData, qrType) => {
  try {
    let response;
    if (qrType === "QRCode") {
      response = await fetch(`${baseURL}/qrcode/update/${qrId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
    } else if (qrType === "VQRCode") {
      response = await fetch(`${baseURL}/qrcode/updatev/${qrId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
    } else {
      throw new Error("Invalid QR code type");
    }

    const data = await response.json();
    if (response.ok) {
      return data; 
    } else {
      throw new Error(data.message || "Failed to update QR code");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchQRCodeByUniqueCode = async function (
  uniqueCode,
  deviceName,
  latitude,
  longitude
) {
  const query = `deviceName=${encodeURIComponent(deviceName)}&latitude=${encodeURIComponent(latitude)}&longitude=${encodeURIComponent(longitude)}`;
  const response = await fetch(
    `${baseURL}/qrcode/getByUniqueCode/${uniqueCode}?${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching QR code by unique code");
  }

  const data = await response.json();
  return {
    qrCodeData: data.qrCodeByUniqueCode,
    deviceAnalytics: data.deviceAnalytics,
    locationAnalytics: data.locationAnalytics,
    viewCount: data.qrCodeByUniqueCode.viewCount || 0,
  };
};

export const fetchVQRCodeByUniqueCode = async function (
  uniqueCode,
  deviceName,
  latitude,
  longitude
) {
  const query = `deviceName=${deviceName}&latitude=${latitude}&longitude=${longitude}`;
  const response = await fetch(
    `${baseURL}/qrcode/getByUniqueVCode/${uniqueCode}?${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching virtual QR code by unique code");
  }

  const data = await response.json();
  return {
    qrCodeData: data.qrCodeByUniqueCode,
    deviceAnalytics: data.vdeviceAnalytics,
    locationAnalytics: data.vlocationAnalytics,
    viewCount: data.qrCodeByUniqueCode.viewCount || 0,
  };
};

export const fetchQRCodeById = async function (qrId, qrType) {
  const endpoint =
    qrType === "VQRCode"
      ? `${baseURL}/qrcode/getByVId/${qrId}`
      : `${baseURL}/qrcode/getById/${qrId}`;

  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching ${qrType} by ID`);
  }

  const data = await response.json();
  return data;
};
