import React from 'react'
// import {
//     CLIENT_ID,
//     IDENTITY_SERVER_URL,
//     CALLBACK_URL, 
// } from "../config";

const Login = () => {

    const openLogin = () => {
        let redirectUrl =
          process.env.REACT_APP_IDENTITY_SERVER_URL +
          "/?callback_url=" +
          process.env.REACT_APP_CALLBACK_URL +
          "&client_id=" +
          process.env.REACT_APP_CLIENT_ID;
      
        window.location.href = redirectUrl;
    };
  return (
    <>
        {openLogin()}
    </>
  )
}

export default Login