import React, { useEffect, useState } from "react";
import "./QrAnalytics.css";
import { fetchQRCodeById } from "../api"; 
import getDeviceName from "./getDevice";
import getLocation from "./getLocation";

const QrAnalytics = () => {
  const [viewCount, setViewCount] = useState(0);
  const [deviceAnalytics, setDeviceAnalytics] = useState([]);
  const [locationAnalytics, setLocationAnalytics] = useState([]);

  const [locationData, setLocationData] = useState();
  const deviceName = getDeviceName(); 
  const qrType = window.location.pathname.split("/")[3];
  const qrId = window.location.pathname.split("/")[4];


  useEffect(() => {
    getLocation(setLocationData);
  }, []);
 

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const { qrCode, deviceAnalytics, locationAnalytics } =
          await fetchQRCodeById(qrId, qrType);
        setViewCount(qrCode.viewCount);
        setDeviceAnalytics(deviceAnalytics);
        setLocationAnalytics(locationAnalytics);
      } catch (error) {
      }
    };

    fetchAnalyticsData();
  }, [qrId, qrType, locationData, deviceName, viewCount]);

  return (
    <div className="analytics-container">
      <h1 className="analytics-title">QR Code Analytics</h1>

      <div className="analytics-section">
        <h2 className="analytics-subtitle">Total View Count</h2>
        <div className="view-count-box">
          <p>View Count: {viewCount}</p>
        </div>
      </div>

      <div className="analytics-section">
        <h2 className="analytics-subtitle">Device Analytics</h2>
        <table className="analytics-table">
          <thead>
            <tr>
              <th>Device Name</th>
              <th>Device Count</th>
            </tr>
          </thead>
          <tbody>
            {deviceAnalytics?.map((device, index) => (
              <tr key={index}>
                <td>{device.deviceName}</td>
                <td>{device.deviceCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="analytics-section">
        <h2 className="analytics-subtitle">Location Analytics</h2>
        <table className="analytics-table">
          <thead>
            <tr>
              <th>Location Name</th>
              <th>Location Count</th>
            </tr>
          </thead>
          <tbody>
            {locationAnalytics?.map((location, index) => (
              <tr key={index}>
                <td>{location.location}</td>
                <td>{location.locationCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QrAnalytics;
