//QRCodeForm.js
import React, { useState, useEffect } from "react";
import {
  generateQRCode,
  getUserdata,
  updateQRCode,
  fetchQRCodeById,
  generateVQRCode,
} from "../api"; 
import { useNavigate } from "react-router-dom";
import "./QRCodeForm.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import { AddCard } from "@mui/icons-material";
import getLocation from "./getLocation";
import getDeviceName from "./getDevice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const QRCodeForm = ({
  qrId,
  onClose,
  edit,
  qrData,
  qrType,
  setQrCode,
  setActiveTab
}) => {

  const [editData, setEditData] = useState({
    qrName: qrData?.qrName,
    fullName: qrData?.fullName,
    mobileNumber: qrData?.mobileNumber,
    emailAddress: qrData?.emailAddress,
    address: qrData?.address,
    website: qrData?.website,
  });

  const [value, setValue] = React.useState(
    qrType === "VQRCode" ? 1 : 
    qrType === "QRCode" && editData?.emailAddress!=="" ? 0 :
    qrType === "QRCode" && editData?.website!=="" ? 2 :
    qrType === "QRCode" && editData?.address!=="" ? 3 :
    qrType === "QRCode" && editData?.mobileNumber!=="" ? 4:
    0
  );
  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    setActiveTab(value); 

  },[value, setActiveTab])

  const navigate = useNavigate();
  const token = localStorage.getItem("qrg:access-token");
  const [userId, setUserId] = useState("");

  const [formData, setFormData] = useState({
    qrName: "",
    fullName: "",
    jobTitle: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    website: "",
  });


  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserdata(token);
      setUserId(response?.id);
    };

    fetchData();
  }, [token]);

const [locationData, setLocationData]=useState()
const deviceName=getDeviceName()

useEffect(()=>{
  getLocation(setLocationData);
},[])

const fetchQRCodeData = async (qrId, qrType) => {
  
  try {
    const qrCodeData = await fetchQRCodeById(qrId, qrType, locationData, deviceName);
    setFormData({ ...qrCodeData });
  } catch (error) {
    console.error("Error fetching QR code data:", error);
  }
};


  useEffect(() => {
    if (qrId && qrType) {
    fetchQRCodeData(qrId, qrType);
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrId, qrType, locationData, deviceName]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setQrCode({ ...formData, [e.target.name]: e.target.value });

  };

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
    setQrCode({ ...editData, [e.target.name]: e.target.value });

  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!edit && !formData.qrName.trim()) {
      setError("QR code name is required");
      return;
    } else if (edit && !editData.qrName.trim()) {
      setError("QR code name is required");
      return;
    }

    try {
      const data = edit ? { ...editData, userId } : { ...formData, userId };
      // let updatedQRCodeData;

      if (qrId) {
        // Update the existing QR code if qrId is present
        await updateQRCode(qrId, data, qrType);    
            // updatedQRCodeData()
        setSuccessMessage("QR code updated successfully");
      } else {
        // Generate a new QR code if qrId is not present
        await generateQRCode(data);
        // updatedQRCodeData()
        setSuccessMessage("QR code generated successfully");
      }

      // Set the updated generated QR code data

      setTimeout(() => {
        onClose();
        navigate(`/dashboard`);
      }, 1000);
    } catch (error) {
      console.error("Error updating/generating QR code:", error);
      setError("An error occurred while updating/generating QR code");
      return; // Exit the function early if there's an error
    }
  };

  const handleVSubmit = async (e) => {
    e.preventDefault();
    if (!edit && !formData.qrName.trim()) {
      setError("QR code name is required");
      return;
    } else if (edit && !editData.qrName.trim()) {
      setError("QR code name is required");
      return;
    }

    try {
      const data = edit ? { ...editData, userId } : { ...formData, userId };
      // let updatedQRCodeData;
      if (qrId) {
        // Update the existing QR code if qrId is present
        await updateQRCode(qrId, data, qrType);
        // updateQRCode()
        setSuccessMessage("QR code updated successfully");
      } else {
        // Generate a new QR code if qrId is not present
        await generateVQRCode(data);
        // updatedQRCodeData()
        setSuccessMessage("QR code generated successfully");
      } 

      setTimeout(() => {
        onClose();
        navigate(`/dashboard`);
      }, 1000);
    } catch (error) {
      console.error("Error updating/generating QR code:", error);
      setError("An error occurred while updating/generating QR code");
      return; // Exit the function early if there's an error
    }
  };

  return (
    <div className="qrcode-form-container">
      <h2 className="form-title">QR Code Form</h2>
      <h4 className="sub-heading">Not all fields are necessary!</h4>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handletabChange}>
          {edit ? (
            <Tab
              icon={<EmailIcon />}
              iconPosition="top"
              label="email"
              disabled={qrType==="VQRCode" || editData?.emailAddress===""}
              {...a11yProps(0)}
            />
          ) : (
            <Tab
              icon={<EmailIcon />}
              iconPosition="top"
              label="email"
              {...a11yProps(0)}
            />
          )}
          {edit ? (
            <Tab
              icon={<AddCard />}
              iconPosition="top"
              label="v card"
              disabled={qrType!=="VQRCode"}
              {...a11yProps(1)}
            />
          ) : (
            <Tab
              icon={<AddCard />}
              iconPosition="top"
              label="v card"
              {...a11yProps(1)}
            />
          )}

          {edit ? (
            <Tab
              icon={<LinkIcon />}
              iconPosition="top"
              label="Url"
              disabled={qrType==="VQRCode" || editData?.website===""}
              {...a11yProps(2)}
            />
          ) : (
            <Tab
              icon={<LinkIcon />}
              iconPosition="top"
              label="Url"
              {...a11yProps(2)}
            />
          )}

          {edit ? (
            <Tab
              icon={<HomeIcon />}
              iconPosition="top"
              label="Address"
              disabled={qrType==="VQRCode" || editData?.address===""}

              {...a11yProps(3)}
            />
          ) : (
            <Tab
              icon={<HomeIcon />}
              iconPosition="top"
              label="Address"
              {...a11yProps(3)}
            />
          )}

          { edit?
          (
          <Tab
            icon={<PhoneIcon />}
            iconPosition="top"
            label="Phone"
            disabled={qrType==="VQRCode" || editData?.mobileNumber===""}
            {...a11yProps(4)}
          />
          ):
        (
          <Tab
          icon={<PhoneIcon />}
          iconPosition="top"
          label="Phone"
          {...a11yProps(4)}
        />
        )
          }
        </Tabs>
          
      </Box>

      <CustomTabPanel value={value} index={0}>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>QR Code Name*</label>
            {edit ? (
              <input
                type="text"
                name="qrName"
                value={editData.qrName}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="qrName"
                value={formData.qrName}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Email Address:</label>
            {edit ? (
              <input
                type="text"
                name="emailAddress"
                value={editData.emailAddress}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
              />
            )}
          </div>
          <button className="generate-btn" type="submit">
            {qrId ? "Update QR Code" : "Generate QR Code"}
          </button>

        </form>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <form className="form" onSubmit={handleVSubmit}>
          <div className="form-group">
            <label>QR Code Name*</label>
            {edit ? (
              <input
                type="text"
                name="qrName"
                value={editData.qrName}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="qrName"
                value={formData.qrName}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Full Name:</label>
            {edit ? (
              <input
                type="text"
                name="fullName"
                value={editData.fullName}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Job:</label>
            {edit ? (
              <input
                type="text"
                name="jobTitle"
                value={editData.jobTitle}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Mobile Number:</label>
            {edit ? (
              <input
                type="text"
                name="mobileNumber"
                value={editData.mobileNumber}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Email Address:</label>
            {edit ? (
              <input
                type="text"
                name="emailAddress"
                value={editData.emailAddress}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Address:</label>
            {edit ? (
              <input
                type="text"
                name="address"
                value={editData.address}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Url:</label>
            {edit ? (
              <input
                type="text"
                name="website"
                value={editData.website}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            )}
          </div>
          <button className="generate-btn" type="submit">
            {qrId ? "Update QR Code" : "Generate QR Code"}
          </button>
        </form>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>QR Code Name*</label>
            {edit ? (
              <input
                type="text"
                name="qrName"
                value={editData.qrName}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="qrName"
                value={formData.qrName}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Url:</label>
            {edit ? (
              <input
                type="text"
                name="website"
                value={editData.website}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            )}
          </div>
          <button className="generate-btn" type="submit">
            {qrId ? "Update QR Code" : "Generate QR Code"}
          </button>

        </form>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>QR Code Name*</label>
            {edit ? (
              <input
                type="text"
                name="qrName"
                value={editData.qrName}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="qrName"
                value={formData.qrName}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Address:</label>
            {edit ? (
              <input
                type="text"
                name="address"
                value={editData.address}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            )}
          </div>
          <button className="generate-btn" type="submit">
            {qrId ? "Update QR Code" : "Generate QR Code"}
          </button>

        </form>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>QR Code Name*</label>
            {edit ? (
              <input
                type="text"
                name="qrName"
                value={editData.qrName}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="qrName"
                value={formData.qrName}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label>Mobile Number:</label>
            {edit ? (
              <input
                type="text"
                name="mobileNumber"
                value={editData.mobileNumber}
                onChange={handleEditChange}
              />
            ) : (
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            )}
          </div>
          <button className="generate-btn" type="submit">
            {qrId ? "Update QR Code" : "Generate QR Code"}
          </button>
        </form>
      </CustomTabPanel>
    </div>
  );
};

export default QRCodeForm;
