import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeGenerator = ({value, name}) => {

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ 
       padding: '5px', 
       fontSize: '20px',
       width: '100%',
       textAlign: 'center',
       justyfyItems: 'center',
       display: 'flex', 
       color: '#283618',
       fontFamily: "'Merriweather', serif",  
       border: '2px dotted #bc6c25',
       borderRadius: '30px',
       background: 'linear-gradient(90deg,#ffddc1, #bc6c25)',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.8)',
       margin: '10px auto 100px auto',
       letterSpacing: '3px',
       lineHeight: '1.2',
       fontWeight: '600',
       fontStyle: 'italic',

           }}
      >Generated QR:</h1> 
      <div>
        <QRCode value={value} size={150} />
        {name && <div style={{ marginTop: '10px', fontSize: '25px', fontWeight:'600', color: '#582f0e', textAlign: 'center',fontFamily: "'Merriweather', serif", letterSpacing: '2px', textShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',

 }}>{name}</div>}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
